.App {
  width: 100%;
  margin: 0 auto;
}

footer {
  display: flex;
  width: 100%;
  height: 64px;
  align-items: flex-end;
  align-content: flex-end;
  gap: 10px 0px;
  flex-wrap: wrap;
}

footer h5 {
  color: var(--text_white, #FFF);
  font-family: Yu Gothic;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.84px;
  margin: 16px auto;
}

.News {
  width: fit-content;
  margin: 16px auto;
}

.Profile {
  width: auto;
  margin: auto;
  background: var(--text_green, #C7EFCA);
}

.ProfileContent {
  width: fit-content;
  margin: auto;
  position: relative;
}

.ProfileContent h2 {
  font-size: 32px;
  font-family: Yu Gothic;
  font-style: normal;
  padding: 16px;
}